html {
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  padding: 0;
  background-color: #f8f8f8;
  font-family: "Open Sans", sans-serif;
}

@media screen and (max-width: 420px) {
  html {
    font-size: 10px;
  }
}

.css-17niava-MuiInputBase-root-MuiOutlinedInput-root {
  background-color: #fff;
}

